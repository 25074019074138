<template>
    <div>
        <h2>Semester Settings</h2>
        <b-spinner v-if="!semester" />
        <template v-else>
            <b-card
                title="Registration"
                sub-title="Settings for semester registration."
            >
                <b-checkbox
                    v-model="settings.registration_open"
                    switch
                    @change="saveSettings()"
                    :value="1"
                    :unchecked-value="0"
                >
                    Allow registration
                </b-checkbox>
                <!-- <b-radio-group :options="[{ text: 'Registration open to everyone', value: 'open'}, { text: 'Registration open for new registrations only', value: 'open-new'}]"></b-radio-group> -->
            </b-card>

            <b-card
                class="my-2"
                title="Teacher Settings"
                sub-title="Settings for teachers views."
            >
                <b-checkbox
                    v-model="settings.allow_class_view"
                    switch
                    @change="saveSettings()"
                >
                    Allow class view
                </b-checkbox>
            </b-card>
        </template>
        <!--
        <b-card
            title="Teacher Settings"
            sub-title="Settings for teachers."
            class="mt-3"
        >
            <b-radio-group :options="[{ text: 'Registration open to everyone', value: 'open'}, { text: 'Registration open for new registrations only', value: 'open-new'}]"></b-radio-group>
        </b-card>
        -->
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            settings: {
                registration_open: false,
                allow_class_view: false
            }
        };
    },
    computed: mapState(["semester"]),
    mounted() {
        this.settings.registration_open = (this.semester) ? this.semester.registration_open : 0;
        this.settings.allow_class_view  = (this.semester) ? this.semester.allow_class_view : false;
    },
    methods: {
        async saveSettings() {
            const { data } = await this.$api.post(
                "/api/admin/save-semester-settings",
                { settings: this.settings }
            );

            this.$bvToast.toast(data.message, {
                title:   (data.success) ? "Success" : "Error",
                variant: (data.success) ? "success" : "danger",
                autoHideDelay: 5000,
                appendToast: true
            });

            if (data.semester) {
                this.$store.commit("setSemester", data.semester);
            }
        }
    },
    watch: {
        semester(semester) {
            this.settings.registration_open = semester.registration_open;
            this.settings.allow_class_view  = (this.semester) ? this.semester.allow_class_view : false;
        }
    }
}
</script>